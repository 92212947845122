import { createActionCreator } from '@reduxify/utils';

import {
  SET_EXPERIMENTS,
  SET_LOADING,
  SET_NEXTROUTE,
  SET_PRODUCTS,
  SET_QUESTIONS,
} from './constants';
import { IFunnelState } from './types';

export const setProducts =
  createActionCreator<IFunnelState['products']>(SET_PRODUCTS);
export const setQuestions =
  createActionCreator<IFunnelState['questions']>(SET_QUESTIONS);
export const setExperiments =
  createActionCreator<IFunnelState['experiments']>(SET_EXPERIMENTS);
export const setLoading =
  createActionCreator<IFunnelState['loading']>(SET_LOADING);
export const setNextRoute =
  createActionCreator<IFunnelState['nextRoute']>(SET_NEXTROUTE);
