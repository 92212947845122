import React, { FC } from 'react';
import styled from 'styled-components';

import { onSurfaceHigh } from 'styles/colors';

enum Type {
  H5Mobile = 'h5-mobile',
  H5 = 'h5',
  H6 = 'h6',
  Btn = 'btn',
  Body = 'body',
  Body2 = 'body2',
  Caption = 'caption',
  S1 = 's1',
  S2 = 's2',
}

enum Align {
  Center = 'center',
  Left = 'left',
  Right = 'right',
  Justify = 'justify',
}

enum FontStyle {
  Normal = 'normal',
  Italic = 'italic',
  Oblique = 'oblique',
}

type AlignType = 'center' | 'left' | 'right' | 'justify';

type FontStyleType = 'normal' | 'italic' | 'oblique';

interface TextProps {
  type:
    | 'h5-mobile'
    | 'h5'
    | 'h6'
    | 'btn'
    | 'body'
    | 'body2'
    | 'caption'
    | 's1'
    | 's2';
  color?: string;
  align?: AlignType;
  fontStyle?: FontStyleType;
}

interface StyledProps {
  color: string;
  align: AlignType;
  fontStyle: FontStyleType;
}

export const Text: FC<TextProps> = ({
  type,
  color = onSurfaceHigh,
  align = Align.Left,
  fontStyle = FontStyle.Normal,
  ...props
}) => {
  switch (type) {
    case Type.H5Mobile:
      return (
        <H5Mobile
          color={color}
          align={align}
          fontStyle={fontStyle}
          {...props}
        />
      );
    case Type.H5:
      return (
        <H5 color={color} align={align} fontStyle={fontStyle} {...props} />
      );
    case Type.H6:
      return (
        <H6 color={color} align={align} fontStyle={fontStyle} {...props} />
      );
    case Type.Btn:
      return (
        <Btn color={color} align={align} fontStyle={fontStyle} {...props} />
      );
    case Type.Body:
      return (
        <Body color={color} align={align} fontStyle={fontStyle} {...props} />
      );
    case Type.Body2:
      return (
        <Body2 color={color} align={align} fontStyle={fontStyle} {...props} />
      );
    case Type.Caption:
      return (
        <Caption color={color} align={align} fontStyle={fontStyle} {...props} />
      );
    case Type.S1:
      return (
        <S1 color={color} align={align} fontStyle={fontStyle} {...props} />
      );
    case Type.S2:
      return (
        <S2 color={color} align={align} fontStyle={fontStyle} {...props} />
      );
    default:
      return null;
  }
};

const H5Mobile = styled.h5<StyledProps>`
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.375rem;
  color: ${({ color }) => color};
  text-align: ${({ align }) => align};
  font-style: ${({ fontStyle }) => fontStyle};
`;

const H5 = styled.h5<StyledProps>`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 1.4375rem;
  line-height: 2.125rem;
  color: ${({ color }) => color};
  text-align: ${({ align }) => align};
  font-style: ${({ fontStyle }) => fontStyle};
`;

const H6 = styled.h6<StyledProps>`
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 1.1875rem;
  line-height: 1.875rem;
  letter-spacing: 0.009375rem;
  color: ${({ color }) => color};
  text-align: ${({ align }) => align};
  font-style: ${({ fontStyle }) => fontStyle};
`;

const Btn = styled.span<StyledProps>`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 0.9375rem;
  letter-spacing: 0.078125rem;
  color: ${({ color }) => color};
  text-align: ${({ align }) => align};
  font-style: ${({ fontStyle }) => fontStyle};
`;

const Body = styled.p<StyledProps>`
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.5rem;
  letter-spacing: 0.03125rem;
  color: ${({ color }) => color};
  text-align: ${({ align }) => align};
  font-style: ${({ fontStyle }) => fontStyle};
`;

const Body2 = styled.p<StyledProps>`
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 0.8125rem;
  line-height: 1.25rem;
  letter-spacing: 0.015625rem;
  color: ${({ color }) => color};
  text-align: ${({ align }) => align};
  font-style: ${({ fontStyle }) => fontStyle};
`;

const Caption = styled.p<StyledProps>`
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.025rem;
  color: ${({ color }) => color};
  text-align: ${({ align }) => align};
  font-style: ${({ fontStyle }) => fontStyle};
`;

const S1 = styled.h5<StyledProps>`
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.5rem;
  letter-spacing: 0.009375rem;
  color: ${({ color }) => color};
  text-align: ${({ align }) => align};
  font-style: ${({ fontStyle }) => fontStyle};
`;

const S2 = styled.h6<StyledProps>`
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1.5rem;
  letter-spacing: 0.00625rem;
  color: ${({ color }) => color};
  text-align: ${({ align }) => align};
  font-style: ${({ fontStyle }) => fontStyle};
`;
