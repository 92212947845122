export const getAllCookies = (): Record<string, string> => {
  const cookies = document.cookie.split('; ');
  const result: Record<string, string> = {};

  cookies.forEach(cookie => {
    const [key, value] = cookie.split('=');
    result[key] = decodeURIComponent(value);
  });

  return result;
};

export const getUrlParams = (): Record<string, string> => {
  const params = new URLSearchParams(window.location.search);
  const result: Record<string, string> = {};

  params.forEach((value, key) => {
    result[key] = value;
  });

  return result;
};

export const getSubdomainIndex = (): number => {
  const domainParts = window.location.hostname.split('.');
  if (domainParts.length === 2) return 1; // example.com
  if (domainParts.length === 3) return 2; // www.example.com
  return 1; // com or other unusual cases
};

export const generateFbc = (fbclid: string): string => {
  const subdomainIndex = getSubdomainIndex();
  const creationTime = Date.now();
  return `fb.${subdomainIndex}.${creationTime}.${fbclid}`;
};

export const setCookie = (name: string, value: string, days: number): void => {
  const expires = new Date(
    Date.now() + days * 24 * 60 * 60 * 1000,
  ).toUTCString();
  document.cookie = `${name}=${encodeURIComponent(value)}; path=/; domain=${
    window.location.hostname
  }; expires=${expires}; SameSite=Lax; Secure`;
};

export const getCookiesByKeys = (keys: string[]): Record<string, string> => {
  const cookies = getAllCookies();
  const urlParams = getUrlParams();
  const result: Record<string, string> = {};

  keys.forEach(key => {
    if (cookies[key]) {
      result[key] = cookies[key];
    } else if (key === '_fbc' && urlParams['fbclid']) {
      const existingFbc = cookies['_fbc'];
      const newFbclid = urlParams['fbclid'];
      const newFbcValue = generateFbc(newFbclid);

      if (!existingFbc || existingFbc.split('.').pop() !== newFbclid) {
        setCookie('_fbc', newFbcValue, 90);
        result[key] = newFbcValue;
      }
    } else if (urlParams[key]) {
      result[key] = urlParams[key];
    }
  });

  return result;
};
